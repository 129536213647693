<template>
    <error-layout>
        <h1 class="title">
            {{ $t('common.pages.error.500.title') }}
        </h1>
        <h2 class="subtitle">
            {{ $t('common.pages.error.500.body') }}
        </h2>

        <div class="columns is-multiline">
            <div class="column is-12">
                <p>
                    <a @click="$router.back()">{{ $t('common.pages.back_to_home') }}</a>
                </p>
            </div>
            <div class="column is-6 is-offset-3">
                <figure class="image">
                    <img src="/img/server-error.svg" alt="Server Error">
                </figure>
            </div>
        </div>
    </error-layout>
</template>

<script>
    import ErrorLayout from "../../components/layout/ErrorLayout";
    export default {
        name: "ServerError",
        components: {ErrorLayout}
    }
</script>

<style scoped>

</style>